import React from 'react'
import CheckboxLabels from '../components/customComponent/Checkbox';
import { downloadFile, getImgSrc } from '../constants/Helper';
import { Button, Typography } from '@mui/material';
import { appTheme } from '../theme/colorTheme';
import DownloadIcon from '@mui/icons-material/Download';

const QrCode = (props) => {
    const [selectedPaymentType, setSelectedPaymentType] = React.useState([])
    return (

        <>
            {props.data.map((row) => (
                <div key={row.id} style={{ display: 'flex', color: appTheme.color1, justifyContent: 'space-between', alignItems: 'center', fontSize: 11, borderBottom: '1px solid white', padding: '10px 0' }}>
                    <div style={{ display: 'flex', color: appTheme.color1, alignItems: 'center' }}>
                        <div style={{ marginRight: '10px', zIndex:0 }}>
                            <CheckboxLabels checked={selectedPaymentType?.id === row?.id} onChange={(e, newValue) => {
                                if (newValue) {
                                    setSelectedPaymentType(row);
                                    props.handleChecked(row, newValue);
                                } else {
                                    setSelectedPaymentType(null)
                                    props.handleChecked(null, newValue);
                                }
                            }} />

                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={!!getImgSrc(row?.account_display_image) ? getImgSrc(row.account_display_image) : require('../img/no-pictures.png')}
                                style={{ verticalAlign: 'middle', width: "110px" }}

                                alt="logo"
                                loading="lazy"
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Typography variant="caption" display="block" gutterBottom fontSize={11}>Display Name:</Typography>
                        <Typography variant="caption" display="block" gutterBottom fontSize={14} fontWeight={"bold"}>
                            {row.account_display_name}
                        </Typography>
                        <Button sx={{
                            float: 'right',
                            color: appTheme.color0, bgcolor: appTheme.color12,
                            "&:hover": {
                                bgcolor: appTheme.color12
                            },
                            focusVisible: false,
                            ml: .5,
                            pb: .5, pl: .5, pt: .5, pr: 1,
                            fontSize: 10
                        }} component="label" onClick={() => {
                            downloadFile(getImgSrc(row?.account_display_image));
                        }}><DownloadIcon fontSize='small' /> Download Qr</Button>
                    </div>
                </div>
            ))}

        </>



    )
}

export default QrCode
import OneSignal from 'react-onesignal';
import { localStorageKey } from './constants/LocalStorageKey';


export default async function runOneSignal() {
  await OneSignal.init({ appId: '2a78ac43-ab03-4f24-bf79-de3ca68867f8', allowLocalhostAsSecureOrigin: true});
  OneSignal.showSlidedownPrompt();
  OneSignal.getUserId().then((userId) => {
   sessionStorage.setItem(localStorageKey.USERID, userId);
  })
}
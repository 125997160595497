import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CustomizedInputs from '../components/customComponent/CustomInput';
import { phoneNumberValidation } from '../constants/Helper';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import RadioButton from '../components/customComponent/RadioButton';
import InformationDailog from './InformationDailog';
import { Typography } from '@mui/material';
import { appTheme } from '../theme/colorTheme';

const CustomTableDesign = ({
    data,
    deletePaymentMethod,
    handleChecked,
    handleEditable,
    index,
    isEditable,
    selectedPaymentType,
    setEditable,
    setSelectedPaymentType,
    updatedPaymentData
}) => {
    
   
    const [accountIdentifier, setAccountIdentifier] = React.useState('');
    const [isAccountIdentifierValid, setIsAccountIdentifierValid] = React.useState(true);
    const checkAccountIdentifierValidation = React.useCallback((value) => {
        if (phoneNumberValidation(value)) {
            setIsAccountIdentifierValid(true)
        } else {
            setIsAccountIdentifierValid(false)
        }
    }, [])
    React.useEffect(() => {
        if(!!data.account_identifier){
            setAccountIdentifier(data.account_identifier)
        }
        if (index === 0) {
            setSelectedPaymentType(data);
        }
    }, [data, index, data.id, handleChecked, setSelectedPaymentType])
    return (
        <>
            <TableRow
                key={data.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: appTheme.color1 }}
            >
                <TableCell padding="checkbox">
                    <RadioButton value={data?.id} selectedValue={selectedPaymentType?.id} handleChange={(e, newValue) => {
                        handleChecked(data);
                        // if (newValue) {
                        //     setSelectedPaymentType(data);

                        // } else {
                        //     setSelectedPaymentType(null)
                        // }
                    }} />
                </TableCell>
                <TableCell sx={{ color: appTheme.color1, fontSize: 11, width: '150px' }} component="th" scope="row">
                    {
                        data.type.toLocaleLowerCase() === 'bank' ?
                            <>
                                <Typography variant="caption" display="block" fontSize={10}>
                                    {data.bank_name}
                                </Typography>
                                <Typography variant="caption" display="block" fontSize={10}>
                                    {data.account_identifier}
                                </Typography>
                                {
                                    index === 0 ? <Typography variant="caption" display="block" fontSize={10} sx={{ color: appTheme.color12 }}>
                                        (Preferred)
                                    </Typography> : ''
                                }
                            </> : <>
                                {data.account_display_name}
                                <CustomizedInputs key={data.id} type="number"
                                    variant="standard"
                                    disabled={!(isEditable?.id === data?.id)}
                                    defaultValue={accountIdentifier} setData={setAccountIdentifier}
                                    checkValidation={checkAccountIdentifierValidation}
                                    isValidation={isAccountIdentifierValid} />
                            </>

                    }

                </TableCell>
                <TableCell sx={{ color: appTheme.color1 }} align="right">
                    {
                        data.type.toLocaleLowerCase() === 'bank' ? <>
                            <EditRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => {
                                updatedPaymentData(data, accountIdentifier)
                            }} />
                            &nbsp; <DeleteForeverRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => {
                                deletePaymentMethod(data);
                            }} />
                        </> : <>
                            {!(isEditable?.id === data?.id) ? <EditRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => {
                                setEditable(data)
                            }} /> : <SaveRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => {
                                if (isAccountIdentifierValid) {
                                    if (accountIdentifier === isEditable.account_identifier) {
                                        setEditable(null)
                                    } else {
                                        updatedPaymentData(isEditable, accountIdentifier)
                                        setEditable(null)
                                    }
                                }


                            }} />}
                            &nbsp; <DeleteForeverRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => {
                                deletePaymentMethod(data);
                            }} />
                        </>
                    }
                </TableCell>
            </TableRow>

        </>

    )
}

const CustomTable = React.memo(CustomTableDesign)

const PaymentDetailsScreen = (props) => {
    let tempSelectedPaymentType = React.useRef();
    const [selectedPaymentType, setSelectedPaymentType] = React.useState(null);
    const [isEditable, setEditable] = React.useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null);

    const [openInfo, setOpenInfo] = React.useState(false);
    const [responseStatus, setResponseStatus] = React.useState('');
    const [statusMessage, setStatusMessage] = React.useState('');
    const [navigateUrl, setNavigateUrl] = React.useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = React.useState('');
    const [buttonTextCancel, setButtonTextCancel] = React.useState('');
    const [isPreferred, setIsPreferred] = React.useState(false);

    const handleEditable = React.useCallback((item) => {
        setEditable(item);
    }, [])

    const updatedPaymentData = (item, accountIdentifier) => {
        props.updatedPaymentData(item, accountIdentifier);
    }

    const deletePaymentMethodConfirmation = React.useCallback((selectedMethod) => {
        setResponseStatus('warning');
        setStatusMessage("Are you sure want to delete ?");
        setButtonTextConfirm('Yes')
        setButtonTextCancel('Cancel')
        setNavigateUrl('');
        setOpenInfo(true);
        setIsPreferred(false);
        setSelectedPaymentMethod(selectedMethod)

    }, []);
    const updatePreferenceConfirmation = React.useCallback((selectedType) => {
        setResponseStatus('warning');
        setStatusMessage("Are you sure want to set this as preferred?");
        setButtonTextConfirm('Confirm')
        setButtonTextCancel('Cancel')
        setNavigateUrl('');
        setIsPreferred(true);
        setOpenInfo(true)
        tempSelectedPaymentType.current = selectedType;

    }, []);

    const { deletePaymentMethod, updatePreferrence } = props;
    const confirmationIsValid = React.useCallback((isPrefrred) => {
        if (!!isPrefrred) {
            setSelectedPaymentType(tempSelectedPaymentType.current);
            updatePreferrence(tempSelectedPaymentType.current);
        } else {
            deletePaymentMethod(selectedPaymentMethod)
        }


    }, [selectedPaymentMethod, deletePaymentMethod, updatePreferrence])
    return (
        <>
            {!!openInfo ? <InformationDailog
                openInfo={openInfo}
                setOpenInfo={setOpenInfo}
                status={responseStatus}
                statusMessage={statusMessage}
                buttonTextConfirm={buttonTextConfirm}
                buttonTextCancel={buttonTextCancel}
                confirmation={confirmationIsValid}
                isPrefrred={isPreferred}
                navigateUrl={navigateUrl} /> : ''}
            <TableContainer sx={{ bgcolor: `black` }}>
                <Table sx={{ color: appTheme.color1 }} aria-label="simple table">
                    <TableBody>
                        {props.data.map((row, i) => (
                            <CustomTable data={row} selectedPaymentType={selectedPaymentType}
                                setSelectedPaymentType={setSelectedPaymentType}
                                isEditable={isEditable}
                                setEditable={setEditable}
                                handleChecked={updatePreferenceConfirmation}
                                handleEditable={handleEditable}
                                updatedPaymentData={updatedPaymentData}
                                deletePaymentMethod={deletePaymentMethodConfirmation}
                                key={i}
                                index={i} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>

    )
}
const PaymentDetails = React.memo(PaymentDetailsScreen)
export default PaymentDetails;


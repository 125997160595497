import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentScreen from "../../layouts/Payment";
import { appTheme } from "../../theme/colorTheme";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector } from "react-redux";
import { speedDialActionKey } from "../../constants/SpeedDialKey";
import { getWhatsAppUrl } from "../../constants/Helper";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SpeedDialComponent from "../../layouts/SpeedDial";
import PaymentVideo from "../../layouts/PaymentVideo";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const actionIconSize = 25;
const speedDialActions = [
    { icon: <WhatsAppIcon />, name: 'Deposit issue?', action: speedDialActionKey.DEPOSITISSUE },
    { icon: <OndemandVideoIcon />, name: 'How to pay video?', action: speedDialActionKey.HOWTOPAY },
];

const PaymentIssue = () => {
    return (
        <div className="button-flashy" style={{
            backgroundColor: "#0fc555", borderTopRightRadius: 0, borderBottomRightRadius: 0,
            borderTopLeftRadius: 100, borderBottomLeftRadius: 100,
            width: 220,
            paddingLeft: 5,
            paddingTop: 3,
            paddingBottom: 3,
            display: "flex", alignItems: "center",
            borderRight: "none", "&:hover": {
                bgcolor: "#0fc555",
            },
        }}>
            <WhatsAppIcon sx={{ mr: 1, color: appTheme.color1 }} />
            <Typography sx={{ fontSize: 10, color: appTheme.color1, fontWeight: 'bold' }}>
                Payment issue? <br></br>
                Contact us
            </Typography>
        </div>
    )
}

const FullPaymentDialogScreen = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [openDialog, setOpenDialog] = React.useState(true);
    const [speedDialState, setSpeedDialState] = React.useState(false);
    const [openPaymentVideo, setOpenPaymentVideo] = React.useState(true);
    const { deposit_number } = useSelector(store => store?.app?.paymentHelplineData)
    const handleDialogClose = () => {
        if (location.key === 'default') {
            navigate('/');
        } else {
            navigate(-1);
        }
        setOpenDialog(false);
    };

    const closeVideo = () => {
        setOpenPaymentVideo(false);
    }
    const checkSpeedDialState = (openFlag) => {
        setSpeedDialState(openFlag)
    }

    const openSppedDialAction = (action) => {
        switch (action) {
            case speedDialActionKey.DEPOSITISSUE:
                window.open(getWhatsAppUrl(deposit_number));
                break;
            case speedDialActionKey.HOWTOPAY:
                setOpenPaymentVideo(true)
                break;
        }
    }
    const getSppeDialStateIcon = () => {
        if (!speedDialState) {
            return <PaymentIssue />
        }
    }



    useEffect(() => {
        if (location.key === 'default') {
            navigate('/');
        }
    }, [location.key, navigate]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (!isShowAdverstisment) {
    //             enableScroll();
    //             const element = document.getElementById("bottomVideo");
    //             element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

    //         }
    //     }, 500)

    //     return () => {
    //         clearTimeout(timer);
    //     }
    // }, [isShowAdverstisment])
    return (
        <>
            <Dialog
                fullScreen
                open={openDialog}
                sx={{ maxWidth: "600px", margin: '0 auto', }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0, zIndex: 1 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Payment
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1 }}>
                    <PaymentScreen />
                    {/* {
                        !isShowAdverstisment && 
                        <div id="bottomVideo" style={{width:'100%', display:'flex', alignItems:'center',
                        justifyContent:'center', marginTop:20}}>
                        <div className="how-to-pay">
                            <div style={{ textAlign: 'center' , color:appTheme.color1}}>
                            <h4>How to pay from upi to bank account</h4>
                            </div>
                            <video controls>
                                <source src={constant.howToPayBankVid} type="video/mp4" />
                            </video>
                        </div>
                        </div>
                    } */}

                </DialogContent>
                {/* {
                    isShowAdverstisment && <PaymentVideo closeVideo={closeVideo} />
                } */}
                {/* {
                    <div style={{
                        padding: 0, color: appTheme.color1, textAlign: 'center', zIndex: 99999,
                        position: 'absolute', top: 5, right: 0,
                    }}>

                        <Fab className="button-flashy" variant="extended" sx={{
                            backgroundColor: "#0fc555", borderTopRightRadius: 0, borderBottomRightRadius: 0,
                            borderRight:"none","&:hover": {
                                bgcolor: "#0fc555",
                            }
                        }}
                            onClick={() => {
                                window.open(whatsAppUrl);
                            }}>
                            <WhatsAppIcon sx={{ mr: 1, color: appTheme.color1 }} />
                            <Typography sx={{ fontSize: 9, color: appTheme.color1, fontWeight: 'bold' }}>
                                Payment issue? <br></br>
                                Contact us
                            </Typography>
                        </Fab>


                    </div>
                } */}
                {
                    openPaymentVideo && <PaymentVideo closeVideo={closeVideo} />
                }


                <SpeedDialComponent
                    cbSpeedial={openSppedDialAction}
                    direction="down"
                    position={{ position: 'fixed', top: speedDialState ? 1 : -10, right: 0 }}
                    actions={speedDialActions}
                    actionIcon={getSppeDialStateIcon()}
                    actionIconSize={speedDialState ? actionIconSize : 0}
                    cbToCheckSpeedDialState={checkSpeedDialState} />

            </Dialog>

        </>
    );
};
const PaymentDailog = React.memo(FullPaymentDialogScreen);
export default PaymentDailog;
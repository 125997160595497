import React from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import { appTheme } from '../../theme/colorTheme';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    let navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        paddingLeft: 2,
        paddingRight: 2,
        color: appTheme.color1
      }}
    >
      <Box>
      <Typography variant="h2">404</Typography>
      <Typography variant="h5">UH OH! You're lost.</Typography>
      <Typography variant="body1" gutterBottom>The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</Typography>
      <Button variant="outlined" onClick={() => navigate('/')}>Home</Button>
      </Box>
    </Box>
  )
}

export default NotFound;
import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Backdrop from '@mui/material/Backdrop';
import { appTheme } from '../theme/colorTheme';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';



const SpeedDialScreen = ({ direction, position, cbSpeedial, actions, actionIcon, actionIconSize, cbToCheckSpeedDialState }) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        if(cbToCheckSpeedDialState){
            cbToCheckSpeedDialState(false);
        }
    };
    const handleClick = () => {
        setOpen(!open);
        if(cbToCheckSpeedDialState){
            cbToCheckSpeedDialState(!open);
        }
    };
    return (
        <>
            <Backdrop open={open} onClick={handleClose} />
            <SpeedDial
                ariaLabel="SpeedDial"
                FabProps={{ style: { backgroundColor: appTheme.color12, color: appTheme.color1, width: actionIconSize ? actionIconSize + 20 : 0, height: actionIconSize ? actionIconSize + 20 : 0, zIndex: 0 } }}
                sx={{ ...position, "& .MuiSpeedDialIcon-root": { height: actionIconSize } }}
                icon={<SpeedDialIcon icon={actionIcon} openIcon={<CloseIcon style={{ width: actionIconSize, height: actionIconSize,  display: 'flex', alignItems: 'center', justifyContent: 'center' }} />} />}
                onClick={handleClick}
                open={open}
                direction={direction}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        sx={{
                            "& .MuiSpeedDialAction-staticTooltipLabel": { whiteSpace: 'nowrap', fontSize: 12 }
                        }}
                        key={action.name}
                        icon={action.icon}
                        tooltipOpen
                        tooltipTitle={action.name}
                        onClick={(e) => {
                            e.stopPropagation();
                            cbSpeedial(action.action);
                            handleClick();
                        }}
                    />
                ))}
            </SpeedDial>
        </>


    );
}

const SpeedDialComponent = React.memo(SpeedDialScreen);
export default SpeedDialComponent;

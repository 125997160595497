import React from 'react'

import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import { appTheme } from '../theme/colorTheme';
const Marquee = ({anoucmentText}) => {
  return (
    <div className="marquee">
    <div style={{display:'flex', alignItems:'center', whiteSpace: 'nowrap'}}><CampaignRoundedIcon fontSize="large" sx={{color:appTheme.color12}}/>
    {anoucmentText}
    </div>
  </div>
  )
}

export default Marquee
import React, { useEffect } from 'react'
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { appTheme } from '../../theme/colorTheme';
import { useLocation, useNavigate } from 'react-router-dom';
import gPayIcon from '../../img/gpay.svg';
import phonePeIcon from '../../img/phonepe.svg';
import upiIcon from '../../img/upi.svg';
import paytmIcon from '../../img/paytm.svg';
import bhimUpi from '../../img/bhim-upi-icon.png';
import constant from '../../constants/Constatnt';
import { speedDialActionKey } from '../../constants/SpeedDialKey';
import { convertBase64toFile, getWhatsAppUrl } from '../../constants/Helper';
import { useSelector } from 'react-redux';
import PaymentVideo from '../../layouts/PaymentVideo';
import SpeedDialComponent from '../../layouts/SpeedDial';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { ReactComponent as SupportIcon } from '../../img/help-desk.svg';
import Link from '@mui/material/Link';
import CustomPrompt from '../../layouts/CustomPrompt';
import { localStorageKey } from '../../constants/LocalStorageKey';
const speedDialActions = [

  { icon: <WhatsAppIcon />, name: 'Deposit issue?', action: speedDialActionKey.DEPOSITISSUE },
  { icon: <OndemandVideoIcon />, name: 'How to pay video?', action: speedDialActionKey.HOWTOPAY },
];
const actionIconSize = 40;
const PaymentGatewayCheckoutScreen = () => {
  const { deposit_number } = useSelector(store => store?.app?.paymentHelplineData)
  const [openInfo, setOpenInfo] = React.useState(true);
  const [openPaymentVideo, setOpenPaymentVideo] = React.useState(false);

  const location = useLocation();
  const configHeader = {
    'Accept' : 'application/json',
    ...(!!location?.state?.gateway_data?.authentication && {'Authorization' : `Bearer ` + localStorage.getItem(localStorageKey.APITOKEN)})
   }
  console.log(location?.state?.gateway_data?.authentication)
  let navigate = useNavigate();

  const confirmationIsValid = async () => {
    try {
      const res = await fetch(location?.state?.gateway_data?.cancel_txn_api, {
        headers: configHeader
      });
      const data = await res.json();
    } catch (error) {
      console.log(error);
    }
  };

  const openSppedDialAction = (action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case speedDialActionKey.DEPOSITISSUE:
        window.open(getWhatsAppUrl(deposit_number));
        break;
      case speedDialActionKey.HOWTOPAY:
        if (!openPaymentVideo) {
          setOpenPaymentVideo(true)
        }
        break;
    }
  }

  const closeVideo = () => {
    setOpenPaymentVideo(false);
  }


  useEffect(() => {
    const getPaymentStatus = async () => {
      try {
        const res = await fetch(location?.state?.gateway_data?.check_status_api, {
          headers: configHeader
        });
        const data = await res.json();
        if (data?.txn_status) {
          if (data.txn_status.toLocaleLowerCase() === constant.transactionStatus.success.toLocaleLowerCase()) {
            setOpenInfo(false)
            navigate({
              pathname: '/transactionStatus',
              search: `?txn_id_number=${location?.state?.transaction_number}&status=${constant.transactionStatus.success}`,
            });
          } else if (data.txn_status.toLocaleLowerCase() === constant.transactionStatus.failure.toLocaleLowerCase()) {
            setOpenInfo(false)
            navigate({
              pathname: '/transactionStatus',
              search: `?txn_id_number=${location?.state?.transaction_number}&status=${constant.transactionStatus.failure}`,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    const interval = setInterval(async () => {
      if (location?.state?.gateway_data?.check_status_api) {
        await getPaymentStatus()
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    }
  }, [openInfo])



  return (
    <>
      {
        openInfo && <CustomPrompt
        when={openInfo}
        message="Are you sure you want to cancel the transaction?"
        setOpenInfo={setOpenInfo}
        callbackAfterConfirmation={confirmationIsValid}
      />
      }
      <div style={{ width: '100%', minHeight: '100vh', paddingTop: 20,
backgroundImage: 'linear-gradient(to top, #c1dfc4 0%, #deecdd 100%)' }}>
        <Container sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: appTheme.color1,
          width: '100%',
          pb: 2,
          borderBottom: `1px solid ${appTheme.color19}`,
        }}>
          <Box>
            <Typography variant="caption" sx={{ color: appTheme.color9, fontSize: 15 }} display="block">Transaction ID</Typography>
            <Typography variant="body2" sx={{ color: appTheme.color0, fontSize: 16 }}><strong>{location?.state?.transaction_number}</strong></Typography>
          </Box>
          <Box>
            <Typography variant="caption" display="block" sx={{ color: appTheme.color19, fontSize: 15 }}>Amount</Typography>
            <Typography variant="body2" sx={{ color: appTheme.color19, fontSize: 16 }}><strong>₹ {location?.state?.gateway_amount}</strong></Typography>
          </Box>

        </Container>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: appTheme.color1,
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
            <Typography variant="body2" display="block" gutterBottom sx={{ color: appTheme.color19, fontSize: 16, mb: 2 }}><strong>Scan QR code with your PSP app</strong></Typography>
            <img src={location?.state?.gateway_data?.image} alt="qrcode" style={{ width: 200, height: 200, marginBottom: 12 }} />
            <Link onClick={() => convertBase64toFile(location?.state?.gateway_data?.image)} variant="body2" sx={{textDecoration: 'underline', color: 'red', fontWeight: 'bold', mb: 2, cursor: 'pointer' }}>
              {'Download QR code'}
            </Link>
            {
              (location?.state?.gateway_data?.upi_intent && Object.keys(location.state.gateway_data.upi_intent).length) && <>
                <Typography variant="body2" sx={{ color: appTheme.color6, mb: 2 }} gutterBottom display="block"><strong>Or</strong></Typography>
                <Typography variant="body2" display="block" gutterBottom sx={{ color: appTheme.color19, fontSize: 16, mb: 2 }}><strong>Pay using below UPI apps</strong></Typography>
                <Box sx={{
                  gap: 1, display: 'flex', justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  width: 200,
                }}>
                  {
                    Object.keys(location.state.gateway_data.upi_intent).map((key, index) => {
                      return <Button key={index} sx={{
                        padding: 0,
                        borderRadius: 0,
                        cursor: 'pointer', background: appTheme.color1, border: `1px solid ${appTheme.color19}`,
                        "&:hover": {
                          bgcolor: appTheme.color1
                        }
                      }}
                        onClick={() => {
                          window.location.href = location.state.gateway_data.upi_intent[key];
                        }}>
                        <img src={
                          key.includes('bhim') ? bhimUpi :
                            key.includes('paytm') ? paytmIcon :
                              key.includes('phonepe') ? phonePeIcon :
                                key.includes('gpay') ? gPayIcon :
                                  key.includes('upi') ? upiIcon : null
                        } alt={key} style={{ width: 80, padding: '3px 10px', }} />
                      </Button>
                    })
                  }
                </Box>
              </>
            }
             <Link onClick={() => setOpenPaymentVideo(true)} variant="body2" sx={{textDecoration: 'underline', color: 'red', fontWeight: 'bold', mt:2, mb: 2, cursor: 'pointer' }}>
              {'Watch Video How to Deposit?'}
            </Link>

            <Typography variant="body2" sx={{ color: appTheme.color19, mb: 2, mt:3 }} gutterBottom display="block"><strong>Note:</strong>
              User are requested to make payment on the given details to avoid any delays and other consequences and also requesting to fill the mandatory fields, if not submitted properly within 1hour of the transaction payment will be deemed as zero. False claims will has legal consequences.
            </Typography>
            <Typography variant="body2" sx={{ color: appTheme.color19, mb: 2, mt: 1 }} gutterBottom display="block"><strong>नोट:</strong>
              उपयोगकर्ता से किसी भी देरी और अन्य परिणामों से बचने के लिए दिए गए विवरण पर भुगतान करने का अनुरोध किया जाता है और लेनदेन भुगतान के 1 घंटे के भीतर ठीक से जमा नहीं किए जाने पर अनिवार्य फ़ील्ड भरने का अनुरोध शून्य माना जाएगा। झूठे दावों के कानूनी परिणाम होंगे।
            </Typography>

          </Box>

        </Container>
      </div>
      <SpeedDialComponent cbSpeedial={openSppedDialAction} direction="up"
        position={{ position: 'fixed', bottom: 80, right: 15 }}
        actions={speedDialActions}
        actionIcon={<SupportIcon style={{ width: actionIconSize, height: actionIconSize }} />}
        actionIconSize={actionIconSize} />
      {
        openPaymentVideo && <PaymentVideo closeVideo={closeVideo} />
      }
    </>
  )
}
const PaymentGatewayCheckout = React.memo(PaymentGatewayCheckoutScreen);
export default PaymentGatewayCheckout;
import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import IdDeposit from "../../layouts/IdDeposit";
import { appTheme } from "../../theme/colorTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullIdDepositDialogScreen = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [openDialog, setOpenDialog] = React.useState(true);
    const handleDialogClose = () => {
        if (location.key === 'default') {
            navigate('/');
        } else {
            navigate(-1);
        }
        setOpenDialog(false);
    };
    React.useEffect(() => {
        if (location.key === 'default') {
            navigate('/');
        }
    }, [location.key, navigate])

    return (
        <>
            <Dialog
                fullScreen
                open={openDialog}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Deposit
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1 }}>
                    <IdDeposit />
                </DialogContent>
            </Dialog>
        </>
    );
};
const IdDepositDailog = React.memo(FullIdDepositDialogScreen);
export default IdDepositDailog;

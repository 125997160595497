import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import WithdrawWallet from "../../layouts/WithdrawWallet";
import axiosService from "../../axios-api-kit/axiosService";
import AddPaymentsDailog from "../../layouts/AddPaymentsDetailsDialog";
import { appTheme } from "../../theme/colorTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullWalletWithdrawDialogScreen = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [openPaymentDialog, setOpenPaymentDialog] = React.useState(false);
    const [selectedPayment, setSelectedPayment] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(true);
    const handleDialogClose = () => {
        if(location.key === 'default'){
            navigate('/');
        }else{
            navigate(-1);
        }
        setOpenDialog(false);
    };

    const getPaymentMethodTypes = React.useCallback(() => {
        axiosService.getPaymentMethodTypes().then((response) => {
            const bankDetails = response?.data?.find(paymentMethod => paymentMethod?.type?.toLocaleLowerCase() === 'bank');
            if (bankDetails?.payment_methods?.length === 0) {
                setSelectedPayment(bankDetails);
                setOpenPaymentDialog(true);
            }
        }).catch((error) => {
        })
    }, [])
    React.useEffect(() => {
        if (location.key === 'default') {
            navigate('/');
        }else{
            getPaymentMethodTypes();
        }
    }, [location.key, navigate, getPaymentMethodTypes])

    return (
        <>
            {!!openPaymentDialog ? <AddPaymentsDailog
                isFromWithdraw={true}
                isBankDetailsUpdate={false}
                selectedPayment={selectedPayment}
                openPaymentDialog={openPaymentDialog}
                getData={getPaymentMethodTypes}
                setOpenPaymentDialog={setOpenPaymentDialog}
            /> : ''}
            <Dialog
                fullScreen
                open={openDialog}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Withdraw
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1 }}>
                    <WithdrawWallet />
                </DialogContent>
            </Dialog>
        </>
    );
};
const WithdrawWalletDailog = React.memo(FullWalletWithdrawDialogScreen);
export default WithdrawWalletDailog;

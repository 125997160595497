import React from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../../theme/theme";
import { Container, Box } from "@mui/system";
import CreateIdsAccordian from "../../layouts/CreateIdsAccordian";
import MyIdDetailCard from "../../layouts/MyIdsDetail";
import { localStorageKey } from "../../constants/LocalStorageKey";
import { appTheme } from "../../theme/colorTheme";
import { useLocation, useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const Ids = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const index =
    !!queryParams.get('index') ? +queryParams.get('index') : !!sessionStorage.getItem(localStorageKey.SETIDSTABSTATE) ?
      +sessionStorage.getItem(localStorageKey.SETIDSTABSTATE) : 1;

  const idTheme = useTheme();
  const [value, setValue] = React.useState(index);

  const handleChange = (event, newValue) => {
    if(!!queryParams.get('index')){
      navigate(`/ids?index=${newValue}`)
    }
    setValue(newValue);
    sessionStorage.setItem(localStorageKey.SETIDSTABSTATE, newValue)
  };

  React.useEffect(() => {
    if (location.key === 'default') {
      navigate('/');
    }
  }, [location.key, navigate])

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          <Box sx={{ color: appTheme.color1, width: '100%', position: 'relative' }}>
            <AppBar position="sticky" sx={{ bgcolor: appTheme.color0, color: appTheme.color1, border: 0 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: appTheme.color12,
                  },
                }}
              >
                <Tab label="My Ids" {...a11yProps(0)} />
                <Tab label="Create ID" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <Stack
              axis={idTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}

            >
              <TabPanel value={value} index={0} dir={idTheme.direction}>
                <MyIdDetailCard />
              </TabPanel>
              <TabPanel value={value} index={1} dir={idTheme.direction}>
                <CreateIdsAccordian />
              </TabPanel>
            </Stack>
          </Box>
        </Container>
      </ThemeProvider>
    </React.Fragment>
  )
}



export default Ids;
import { createSlice } from "@reduxjs/toolkit";

const headerIntersectionSlice = createSlice({
    name:'headerIntersection',
    initialState:{
        isIntersecting: true
    },
    reducers:{
        setIsIntersecting:(state,action) => {
            state.isIntersecting = action.payload
        }
    }
});


export const { setIsIntersecting } = headerIntersectionSlice.actions;

export default headerIntersectionSlice.reducer;
import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { appTheme } from "../../theme/colorTheme";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const CheckboxLabelsInput = (props) => {
  return (
    <Checkbox
      disabled={props.disabled}
      checked={props.checked}
      onChange={props.onChange}
      {...label}
      sx={{
        padding:0,
        color: appTheme.color6,
        "&.Mui-checked": {
          color: appTheme.color12,
        },
        "&.Mui-disabled": {
          color: appTheme.color10,
        },
      }}
    />
  );
};
const CheckboxLabels = React.memo(CheckboxLabelsInput);

export default CheckboxLabels;

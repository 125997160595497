import React from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import { appTheme } from "../theme/colorTheme";
import constant from "../constants/Constatnt";
const BasicInfo = () => {
  return (
    <List sx={{ color: appTheme.color1, padding:0}}>
      <ListItem alignItems="flex-start" sx={{padding:0}}>
        <ListItemAvatar>
          <img
            style={{
              width: '55px',
              borderRadius: 5, backgroundColor: appTheme.color11, padding: 5,
                  justifyContent: "center", alignItems: "center", verticalAlign: 'middle',
             
              marginRight:'10px'
            }}
            src={require(`../img/${constant.logoPath}`)}
            alt="logo"
            loading="lazy"
          />

        </ListItemAvatar>
        <ListItemText
          primary={
            <>
            <Typography component="div"
                variant="body2">
            Begin your Journey
            </Typography>
            </>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color={appTheme.color16}
              >
                By creating your first ID with {constant.websiteName}
              </Typography>
              <Typography
                display="block"
                component="span"
                variant="caption"
                color={appTheme.color1}
                fontSize={10}
              >
                We got something for the wild punter in you.
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
};

export default BasicInfo;

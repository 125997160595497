import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { appTheme } from '../theme/colorTheme';
import CloseIcon from '@mui/icons-material/Close';
import constant from '../constants/Constatnt';
import { localStorageKey } from '../constants/LocalStorageKey';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DownloadAppModal = (props) => {

    const handleClose = () => {
        props.setOpenInfo(false)
    }

    return (
        <>
            <Dialog
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: 'transparent'
                    },

                }}
                open={props.openInfo}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >

                <div style={{
                    border: '2px solid' + appTheme.color3,
                    borderRadius: '12px'
                }}  onClick={() => {
                    window.open(constant.apkUrl);
                    handleClose();
                    localStorage.setItem(localStorageKey.ISDOWNLOADAPKPOPUPOPEN, JSON.stringify(false));
                }}>
                    <img src={require(`../img/download-add-popup.png`)} alt="Download App" style={{ width: "100%", padding: 0, marginBottom: '-7px' }} />

                </div>
                <button style={{ position: 'absolute', top: 0, zIndex: 99999, border: 0, right: 0 }} className='modalClose'
                onClick={() =>{
                    handleClose();
                    localStorage.setItem(localStorageKey.ISDOWNLOADAPKPOPUPOPEN, JSON.stringify(false));
                }}>
                    <CloseIcon />
                </button>
            </Dialog>
        </>
    );
}

export default DownloadAppModal;

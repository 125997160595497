import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useNavigate } from "react-router-dom";
import { appTheme } from "../theme/colorTheme";
import { Typography } from "@mui/material";
import useDelayUnmount from "../utils/useDelayUnmont";
import depositIcon from '../img/deposit_icon.png';
import withdrawIcon from '../img/withdraw_icon.png';
import SnackBar from "./SnackBar";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';

const HeaderBar = (props) => {
  const navigate = useNavigate();
  const isIntersecting = useSelector(store => store.headerIntersection.isIntersecting);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const { wallet_balance } = useSelector(store => store?.app?.userInfo);
  const {withdraw_status, hold_withdrawal_remarks } = useSelector(store => store?.app?.withdrawalStatus);
  const showDiv = useDelayUnmount(isIntersecting,300);
  const mountedStyle = { animation: "inAnimation 450ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 470ms ease-out",
    animationFillMode: "forwards"
  };
  const handleDrawerToggle = () => {
    props.openDrawewr()
  }

  return (
    <>
     <SnackBar
        setOpen={setOpenSnackbar}
        open={openSnackbar}
        message={snackBarMessage}
      />
      <AppBar position="fixed" sx={{
        maxWidth: "600px",
        bgcolor: appTheme.color0,
        color: appTheme.color1,
        boxShadow: '0px 0px 0px 0px',
        margin: "0 auto",
        left: 'auto',
        right: 'auto'
      }}>
        <Toolbar>
          {
            showDiv && <div className="transitionDiv display-block-header" style={isIntersecting ? mountedStyle : unmountedStyle}>
          <MenuIcon sx={{cursor:'pointer'}} onClick={handleDrawerToggle}/>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="withdrawal details"
              color="inherit"
              component={NavLink}
              to="/withdrawDetails"
              label="withdrawDetails"
              value="withdrawDetails"
            >
              <Badge color="error">
                <AccountBalanceRoundedIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show notifications"
              color="inherit"
              component={NavLink}
              to="/notifications"
              label="notifications"
              value="notifications"
            >
              <Badge badgeContent={0} color="warning">
                <NotificationsNoneRoundedIcon />
              </Badge>
            </IconButton>
          </Box>
            </div>
          }
         {
          !showDiv && 
          <div className="transitionDiv display-block-wallet"
          style={!isIntersecting ? mountedStyle : unmountedStyle} >
            <Box sx={{ display: 'flex', width:'100%',  justifyContent:'space-between', alignItems:'center', boxShadow:"0 0 10px rgba(0, 0, 0, 0.3)" }}>
          <Box sx={{display:'flex', alignItems:'center'}}>
          <MenuIcon sx={{cursor:'pointer', mr:1}} onClick={handleDrawerToggle}/>
            <Typography variant="h6" fontWeight={"bold"} display="block">
             <span style={{ color: appTheme.color6 }}>₹</span><span style={{ color: appTheme.color12 }}>{wallet_balance}</span>
            </Typography> 
          </Box>
          <Box sx={{display:'flex'}}>
            <Box
            sx={{textAlign:'center', textDecoration:'none', color:appTheme.color1, cursor:'pointer'}} 
            onClick={() => {
              navigate('/walletDeposit');
            }}>
                 <img
                  src={depositIcon}
                  width="60"
                  alt="logo"
                /> 
                <Typography variant="caption" display="flex" gutterBottom fontSize={11} sx={{mt:-2, justifyContent:'center'}}>
                 Deposit
                  </Typography>
            </Box>
            <Box 
            sx={{textAlign:'center', textDecoration:'none', color:appTheme.color1, cursor:'pointer', opacity:withdraw_status === 'off' ? 0.4 : 1}} 
            onClick={() => {
              if(withdraw_status === 'off'){
                setSnackBarMessage(hold_withdrawal_remarks || 'Withdraw wallet on hold, we are under maintenance!');
                setOpenSnackbar(true);
              }else{
                navigate('/withdrawWallet')
              }
            }}>
                <img
                  src={withdrawIcon}
                  width="60"
                  alt="logo"
                />
                 <Typography variant="caption" display="flex" gutterBottom fontSize={11} sx={{mt:-2, justifyContent:'center'}}>
                 {withdraw_status === 'off' && <ConstructionOutlinedIcon sx={{fontSize:15}}/>} Withdraw
                  </Typography>
              </Box>
           
          </Box>
        </Box>
          </div>
          
         }
        </Toolbar>
      </AppBar>
      <Toolbar />

    </>
  );
};
const Header = React.memo(HeaderBar)
export default Header;


const constant = {
    websiteName:"LotusBaazi",
    baseUrl:"https://ptanhibhai.com/",
    whatsAppNumber:"+919990625625",
    instagramUrl:"https://api.whatsapp.com/send/?phone=9990625625&text&type=phone_number&app_absent=0",
    telegramUrl:"https://api.whatsapp.com/send/?phone=9990625625&text&type=phone_number&app_absent=0",
    youTubeUrl:"https://api.whatsapp.com/send/?phone=9990625625&text&type=phone_number&app_absent=0",
    apkUrl:"https://lotusbaazi.com/android/LotusBaazi.apk",
    landingPageLogoSize:"70px",
    logoPath:"logo.png",
    minDepositCoins:100,
    minWithdrawalCoins:500,
    howToPayBankVid:"https://baazi-cdn.sgp1.cdn.digitaloceanspaces.com/howtoplay/UpiToBankAccountFairBaazi.mp4",
    numberOfIdsOnHome:15,
    maxHoursToShowPaymentVideo: 5,

    transactionType: {
        depositWallet: 'DepositWalletRequest',
        withdrawWallet: 'WithdrawWalletRequest',
        depositId: 'DepositIdRequest',
        withdrawId: 'WithdrawIdRequest',
        createIdRequest: 'CreateIdRequest',
        changeIdPasswordRequest: 'ChangeIdPasswordRequest',
        closeIdRequest:'CloseIdRequest',
    },
    transactionFor: {
        idDeposit: 'idDeposit',
        walletDeposit: 'walletDeposit',
        createIdDeposit: 'createIdDeposit',
        changePasswordRequest: 'changePasswordRequest'
    },
    accountType: {
        savings: 'savings',
        current: 'current'
    },
    transactionStatus: {
        success: 'success',
        scanning: 'scanning',
        failure: 'failure',
        pending: 'pending',
        created: 'created',
        error:'error',
        cancelled:'cancelled',
    }
}

export default constant;
import React from "react";
import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { appTheme } from "../theme/colorTheme";

const HorizontalScroll = (props) => {
    const extraSmallDevice = useMediaQuery('(max-width:600px)');
    const scrollContentWidth = !!extraSmallDevice?170:240;
    const scrollIntoForWard = () => {
        const element = document.getElementById("scrollRef");
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        element.scrollLeft = element.scrollLeft + (scrollContentWidth + 10);
    }
    const scrollIntoBackWard = () => {
        const element = document.getElementById("scrollRef");
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        element.scrollLeft = element.scrollLeft - (scrollContentWidth + 10);
    }
    const StyledIconButton = styled(IconButton)`
height: 35px;
width: 35px;
position: absolute;
border-radius: 16px;
right:${(props) => (props?.isleft === "true" ? "none" : "0")};;
left:${(props) => (props?.isleft === "true" ? "0" : "none")};
top:35%;
background-color: rgba(0, 0, 0, 0.3);
color: white;
border-color: rgba(0, 0, 0, 0.3);
border: 1px solid rgba(0, 0, 0, 0.3);
z-index: 1;
opacity: 1;
:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.5), 0px 1px 10px rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
`;
    return (

        <>
            <Box sx={{ position: 'relative' }}>
                <StyledIconButton isleft="false" onClick={() => {
                    scrollIntoForWard();
                }}>
                    <ArrowForwardIosRoundedIcon />
                </StyledIconButton>
                <StyledIconButton isleft="true" onClick={() => {
                    scrollIntoBackWard();
                }}>
                    <ArrowBackIosNewRoundedIcon />
                </StyledIconButton>

                <Box
                    id="scrollRef" sx={{
                        width: "100%", overflow: "auto", display: "flex", mt: 2, mb: 2,
                        "&::-webkit-scrollbar": {
                            display: "none"
                        },
                        scrollBehavior: 'smooth', transition: "transform 0.6s ease-in"
                    }} >

                    {
                        props.data.map((item) => (
                            <div key={item.id} style={{
                                marginRight: 16,
                                marginLeft: 0,
                                position: 'relative',
                                zIndex: 0,
                                cursor:'pointer'
                            }} onClick={() => {
                                if(!!props.navigate) props.navigate(props.navigationEndpoint)
                            }} >
                                <Paper style={{
                                    width:`${scrollContentWidth}px`,
                                    transition: "transform 0.6s ease-in", backdropFilter: 'blur(40px)', backgroundColor: "transparent", color: appTheme.color1, borderRadius: 10,
                                }}>
                                    <img src={item.image_url} alt="scroll" style={{
                                        width:'100%',
                                        display:'block'
                                    }}/>
                                </Paper>
                            </div>
                        ))
                    }
                </Box>
            </Box>
        </>
    );
};

const HorizontalScrollComponent = React.memo(HorizontalScroll);

export default HorizontalScrollComponent;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { appTheme } from '../theme/colorTheme';
import CloseIcon from '@mui/icons-material/Close';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const TransactionScreenShotModal = (props) => {

    const handleClose = () => {
        props.setOpenInfo(false)
    }

    return (
        <>
            <Dialog
                sx={{
                    padding:0,
                    "& .MuiPaper-root": {
                        backgroundColor: 'transparent'
                    },

                }}
                open={props.openInfo}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >

                <div style={{
                    border: '1px solid' + appTheme.color3,
                }}  onClick={() => {
                    handleClose();
                }}>
                    <img src={props.imgSrc} alt="transaction screenshot" style={{ width: props.width, padding: 0, marginBottom: '-3px' }} />

                </div>
                <button style={{ position: 'fixed', top: 10, zIndex: 99999, border: 0, right: 10 }} className='modalClose'
                onClick={() =>{
                    handleClose();
                }}>
                    <CloseIcon />
                </button>
            </Dialog>
        </>
    );
}

export default TransactionScreenShotModal;

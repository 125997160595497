import React from "react";
import { nameValidation } from "../constants/Helper";
import axiosService from "../axios-api-kit/axiosService";
import CustomizedInputs from "../components/customComponent/CustomInput";
import { Button, Stack } from "@mui/material";
import { appTheme } from "../theme/colorTheme";
import { useSelector } from "react-redux";

const UpiDetails = (props) => {
    const {selectedAccount, paymentTypeValidation, handleClose, getData} = props;
    const { id } = useSelector(store => store?.app?.userInfo);
    const [name, setName] = React.useState("");
    const [isNameValid, setIsNameValid] = React.useState(true);
    const [number, setNumber] = React.useState("");
    const [isNumberValid, setIsNumberValid] = React.useState(true);

    const paymentType = selectedAccount.type.toLocaleLowerCase() === 'upi'? 'Upi Id':`number`;
    const inputTypeForId = selectedAccount.type.toLocaleLowerCase() === 'upi'?'text':'number'
    
    const isFormValid = (nameCheck, numberCheck) => {
        if (
            isNameValid &&
            nameCheck.length !== 0 &&
            isNumberValid &&
            numberCheck.length !== 0
        ) {
            const payload = new URLSearchParams()
            payload.append('account_display_name', name);
            payload.append('account_identifier', number);
            payload.append('is_current_account', false);
            payload.append('payment_method_type_id', selectedAccount?.id)
            payload.append('user_id', id)
            axiosService.addMethodTypes(payload).then((response) => {
                getData();
                handleClose();
            }).catch(error => {
            })

        }

    };

    const checkName = React.useCallback((value) => {
        if (nameValidation(value)) {
            setIsNameValid(true)
        } else {
            setIsNameValid(false)
        }
    }, []);

    const checkNumberValidation = React.useCallback((value) => {
        if (paymentTypeValidation(value)) {
            setIsNumberValid(true)
        } else {
            setIsNumberValid(false)
        }
    }, [paymentTypeValidation]);

    const addDetails = (nameCheck, numberCheck) => {
        checkName(nameCheck);
        checkNumberValidation(numberCheck);
        isFormValid(nameCheck, numberCheck);
    }

    React.useEffect(() => {

    }, [])


    return (
        <React.Fragment>
            <CustomizedInputs key={"1"} placeholder="Enter Name"
                label="Name*" defaultValue={name}
                setData={setName}
                isValidation={isNameValid}
                checkValidation={checkName} />
            <CustomizedInputs key={"2"} placeholder={`Enter ${paymentType}`}
                label={`${selectedAccount.name} ${paymentType}*`} defaultValue={number}
                type={inputTypeForId}
                setData={setNumber}
                isValidation={isNumberValid}
                checkValidation={checkNumberValidation}
            />
            <Stack spacing={2} sx={{
                mt: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Button sx={{
                    fontSize: 11,
                    color: appTheme.color0, bgcolor: appTheme.color12,
                    "&:hover": {
                        bgcolor: appTheme.color12
                    },
                    focusVisible: false
                }} onClick={() => {
                    addDetails(name, number)
                }}>Add</Button>
            </Stack>

        </React.Fragment>
    );
};

const UpiDetailsFormScreen = React.memo(UpiDetails);
export default UpiDetailsFormScreen;

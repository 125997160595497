import React from "react";
import { Snackbar } from "@mui/material"

const SnackBarAlert = (props) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          props.setOpen(false);
    }
    return (
        <Snackbar
        open={props.open}
        autoHideDuration={1500}
        onClose={handleClose}
        message={props.message}
      />
    )
}

const SnackBar = React.memo(SnackBarAlert);
export default SnackBar;    
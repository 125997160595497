import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { appTheme } from '../theme/colorTheme';

const CreateIdsDetailScreen = (props) => {
 
  return (
    <React.Fragment>
      <Stack
        sx={{ color: appTheme.color1 }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="caption" gutterBottom>
             <a href={`https://${props.idData.access_url}`} target='blank' style={{ color: `white`, cursor: 'pointer', textDecoration: 'none' }}>
             Demo ID <OpenInNewIcon fontSize="small" sx={{ fontSize: 14, verticalAlign: 'middle' }} />
                        </a>
          </Typography>
          <Typography variant="caption" gutterBottom component="div">
            <CopyToClipboard text={props.idData.demo_id}>
              <span style={{ cursor: 'pointer', fontWeight:'bold'}} onClick={props.handleClick}>
              {props.idData.demo_id}&nbsp;
                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
            </CopyToClipboard>
          </Typography>
          </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="caption" gutterBottom>
            Demo Password
          </Typography>
          <Typography variant="caption" gutterBottom component="div">
            <CopyToClipboard text={props.idData.demo_password}>
              <span style={{ cursor: 'pointer', fontWeight:'bold' }} onClick={props.handleClick}>{props.idData.demo_password}&nbsp; 
                <ContentCopyOutlinedIcon fontSize='small' sx={{ fontSize: 14, verticalAlign: 'middle' }} /></span>
            </CopyToClipboard>
          </Typography>
        </Box>
      </Stack>
      <TableContainer sx={{ bgcolor: appTheme.color0 }}>
        <Table aria-label="simple table">
          <TableHead >
            <TableRow >
              <TableCell sx={{ color: appTheme.color1, pt: 0, pb: 0 }}>
                <Typography variant="caption" gutterBottom component="div">
                  Min Bet
                </Typography>
              </TableCell>
              <TableCell sx={{ color: appTheme.color1 }} align="right">
                <img alt='coins' src={require("../img/coins-icon.png")} width="20" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.idData.all_games.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1, pt: 1 }} component="th" scope="row">
                  <Typography variant="caption" gutterBottom component="div" fontSize={11}>
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: 0, color: appTheme.color1, pb: 1, pt: 1 }} align="right">
                  <Typography variant="caption" gutterBottom component="div" fontSize={11}>
                    {row.min_bet}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
const CreateIdsDetail = React.memo(CreateIdsDetailScreen);
export default CreateIdsDetail;
